import {
  BodyLight,
  Button,
  CaptionLight,
  Colors,
  MediaQuery,
} from '@pelando/components';
import { styled } from 'styled-components';

const HEADER_HEIGHT_ONBOARDING = 48;

export const IncentiveContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100vw;
  min-height: 85vh;

  margin-top: -${HEADER_HEIGHT_ONBOARDING}px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const IncentiveImage = styled.img`
  width: 100vw;
`;

export const DownloadButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`;

export const IncentiveContinue = styled.span`
  ${CaptionLight}
  margin-top: 16px;
  color: rgb(${Colors.White});
`;

export const IncentiveContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: fixed;
  padding: 32px 16px;

  border-radius: 16px 16px 0px 0px;
  background-color: rgb(${Colors.Coal});
  bottom: 0;
  left: 0;
`;

export const IncentiveLabel = styled.span`
  ${BodyLight}
  color: rgb(${Colors.White});
  text-align: center;
  max-width: 340px;
`;
