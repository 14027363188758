import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Icon, MediaQuery, useMediaQuery } from '@pelando/components';
import { useEffect } from 'react';
import { addOnboardingStageLocalStorage } from '@/infra/storage/onboardingManagement';
import { ChevronRight } from '@pelando/fontawesome/icons';
import { URL_APP_ONBOARING } from '@/presentation/services/urls';
import {
  DownloadButton,
  IncentiveContainer,
  IncentiveContent,
  IncentiveContinue,
  IncentiveImage,
  IncentiveLabel,
} from './style';
import { StepTypes } from '../consts';

const INCENTIVE_IMAGE_TYPE_B = '/assets/onboardingNew/app-incentive-type-B.png';

type IncentiveStepProps = {
  onClickContinue: () => void;
};

const IncentiveStep = ({ onClickContinue }: IncentiveStepProps) => {
  const { t } = useTranslation('onboarding');
  const isMobile = useMediaQuery(MediaQuery.SCREEN_SM);

  useEffect(() => {
    if (!isMobile) {
      addOnboardingStageLocalStorage(StepTypes.VIDEO_STEP);
      onClickContinue();
      return;
    }

    addOnboardingStageLocalStorage(StepTypes.INCENTIVE_STEP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <IncentiveContainer>
      <IncentiveImage
        alt="Procurando mais produtos no app"
        src={INCENTIVE_IMAGE_TYPE_B}
        fetchPriority="high"
      />
      <IncentiveContent>
        <IncentiveLabel>{t('incentive-app-continue-label')}</IncentiveLabel>
        <DownloadButton role="link" url={URL_APP_ONBOARING} target="_blank">
          {t('incentive-app-download-button')}
          <Icon
            icon={ChevronRight}
            style={{ fontSize: 24 }}
            aria-label="Open App"
          />
        </DownloadButton>
        <IncentiveContinue onClick={onClickContinue}>
          {t('incentive-app-continue-button')}
        </IncentiveContinue>
      </IncentiveContent>
    </IncentiveContainer>
  );
};

export default IncentiveStep;
